import { iframeResizer } from 'iframe-resizer';
import queryString from 'query-string';

/**
 * The embedding code running on the content page (i.e., the third party website
 * hosting our iframe). Will determine the iframe's source, create the iframe
 * on the specified div, and setup the resizer.
 */
(function main() {
  /**
   * Main setup function.
   */
  function setupNode(node) {
    const src = getIFrameSource(node);
    if (!src) {
      return;
    }

    const iframe = createIFrame(node, src);
    if (!iframe) {
      return;
    }

    setupResizer(iframe);
  }

  /**
   * Determines the iframe source.
   */
  function getIFrameSource(node) {
    // Determine host and language
    const host = getHost();
    const lang = node.getAttribute('data-lang');
    const baseUrl = lang ? `${host}/${lang}` : host;

    // We support two IFrame types, by applet uid or by type
    const appletUid = node.getAttribute('data-uid');
    const embedType = node.getAttribute('data-type');
    if (appletUid) {
      // So, some people would like to pre-set some filtering parameters. An
      // applet could then be shown multiple times in different spots (these
      // are our sales offerings, these are our offerings in Zurich, etc.).
      const query = {};
      const add = (queryField, dataField) => {
        if (node.getAttribute(dataField) != null) {
          query[queryField] = node.getAttribute(dataField).split(',');
        }
      };
      add('object_category', 'data-initial-object-category');
      add('offer_type', 'data-initial-offer-type');
      add('min_rooms', 'data-initial-min-rooms');
      add('max_rooms', 'data-initial-max-rooms');
      add('min_price', 'data-initial-min-price');
      add('max_price', 'data-initial-max-price');
      add('min_floor', 'data-initial-min-floor');
      add('max_floor', 'data-initial-max-floor');
      add('min_space', 'data-initial-min-space');
      add('max_space', 'data-initial-max-space');
      add('min_year_built', 'data-initial-min-year-built');
      add('max_year_built', 'data-initial-max-year-built');
      add('is_liked', 'data-initial-is-liked');
      add('is_furnished', 'data-initial-is-furnished');
      add('is_temporary', 'data-initial-is-temporary');
      add('ordering', 'data-initial-ordering');
      add('attribute', 'data-initial-attribute');
      add('query', 'data-initial-search');

      const queryStr = queryString.stringify(query);
      return `${baseUrl}/applet/${appletUid}/?${queryStr}`;
    }

    if (embedType) {
      return getIFrameSourceByType(node, baseUrl);
    }

    console.warn('Missing data-uid or data-type'); // eslint-disable-line no-console
    return undefined;
  }

  function getIFrameSourceByType(node, baseUrl) {
    // Get organization slug
    const organization = node.getAttribute('data-organization');

    // Get URL query string
    const query = node.getAttribute('data-query');
    const queryStr = query ? `?${query}` : '';

    // Evaluate embed type and final source
    const embedType = node.getAttribute('data-type');
    switch (embedType) {
      /**
       * LEGACY 2020-08-14:
       * Replaced `listings` and `apply` with applets. Still needed until
       * customer success moved all customers away.
       */
      case 'listings': {
        return `${baseUrl}/${organization}/embedded-listings/${queryStr}`;
      }
      case 'apply': {
        return `${baseUrl}/${organization}/embedded-apply/${queryStr}`;
      }

      default:
        console.warn('Invalid type: ', embedType); // eslint-disable-line no-console
        return undefined;
    }
  }

  /**
   * Creates and appends the iframe if there isn't already one.
   */
  function createIFrame(node, src) {
    // Bail out if iframe was already created. This is, well a bit, expected,
    // so don't bark.
    if (node.querySelectorAll('iframe').length > 0) {
      return undefined;
    }

    // Create iframe
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.title = 'Flatfox';

    // Set base attributes. This is taken from disqus. iframe-resizer will set the
    // height dynamically.
    iframe.width = '100%';
    iframe.tabIndex = 0;
    iframe.horizontalscrolling = 'no';
    iframe.verticalscrolling = 'no';

    // Set style. NB: Need to use setAttribute() because IE11 (for once) adheres
    // to the spec.
    iframe.setAttribute('style', 'width: 100%; border: none; overflow: hidden;');

    // Let's add it to the DOM.
    node.appendChild(iframe);

    return iframe;
  }

  /**
   * Tries to get the host this script was served from. Returns flatfox.ch
   * as fallback.
   */
  function getHost() {
    const script = getScriptNode();
    if (!script || !script.src) {
      return 'https://flatfox.ch';
    }
    const parser = document.createElement('a');
    parser.href = script.src;
    return `${parser.protocol}//${parser.host}`;
  }

  function getScriptNode() {
    // This will work in all newer browsers, but not IE11
    if (document.currentScript) {
      return document.currentScript;
    }
    return undefined;
  }

  /**
   * Starts the iframe resizer on the given iframe
   */
  function setupResizer(iframe) {
    iframeResizer(
      {
        log: false,
        checkOrigin: false, // Must be disabled to silence a few warnings.

        // We'll resize height only.
        sizeHeight: true,
        sizeWidth: false,

        resizeFrom: 'child',
      },
      iframe
    );
  }

  // We'll just walk through all the nodes marked with data-embed
  const nodes = document.querySelectorAll('[data-embed=flatfox]');
  if (nodes.length === 0) {
    console.warn('No embedding nodes found.'); // eslint-disable-line no-console
    return;
  }

  // NB: can't do forEach because of ... IE.
  for (let i = 0; i < nodes.length; i += 1) {
    setupNode(nodes[i]);
  }
})();
